import { React, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Container, Spinner} from 'react-bootstrap';
import ContactInfo from '../components/ContactInfo';
import ConfirmationModal from '../components/ConfirmationModal';

function Contact() {
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(p => !p);

    const [isNameInvalid, setIsNameInvalid] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);

    var confirmationMessage = 
    `You should recieve a confirmation email shortly. 
    Please give us a call at 262-374-1339 for any questions or concerns.`;
    
    function handleSubmit(e){

      let name = document.getElementById("formGridName");
      let email = document.getElementById("formGridEmail");
      let phone = document.getElementById("formGridPhone");

      setIsNameInvalid(false);
      setIsEmailInvalid(false);
      setIsPhoneInvalid(false);
      setSpinnerHidden(false);

      fetch("/api/SendMessages", {
          method: "POST",
          body: JSON.stringify({
                name: name.value,
                email: email.value,
                phone: phone.value,
                address: document.getElementById("formGridAddress").value,
                subject: document.getElementById("formGridSubject").value,
                message: document.getElementById("formGridMessage").value,
          }),
          headers: {
              "Content-type": "application/json; charset=UTF-8"
          }
      })
      .then(response => response.json())
      .then((responseData => 
      {
        if (responseData.field === 'name')
        {
          setIsNameInvalid(true);
          setSpinnerHidden(true);
        }
        else if (responseData.field === 'email'){
          setIsEmailInvalid(true);
          setSpinnerHidden(true);
        }
        else if (responseData.field === 'phone'){
          setIsPhoneInvalid(true);
          setSpinnerHidden(true);
        }
        else{
          toggleShow();
          setSpinnerHidden(true);
        }
      }
      ));
    }

    return (
      <Container fluid style={{marginTop: "auto"}}>
        <ConfirmationModal 
          show={show} 
          toggleShow={toggleShow} 
          title={"Estimate request submitted!"}
          text={confirmationMessage}/>
        <Row className="m-auto" style={{marginTop: "auto", width: "80%", paddingBottom: "1rem", paddingTop: "1rem"}}>
          <Col xs={12} sm={12} md={6} className="d-flex align-items-center" style={{paddingBottom: "1rem"}}>
            <ContactInfo message={"For Your Free Estimate\nContact Us"}/>
          </Col>
          <Col xs={12} sm={12} md={6} style={{
            alignItems: "center", 
            justifyContent: "center",
            backgroundColor: "#1E1C1B",
            paddingTop: "1rem",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "2rem",
            borderRadius: "1rem"}}>
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label className="FormLabel">Name</Form.Label>
                  <Form.Control className="FormInput" type="name" placeholder="Name" isInvalid={isNameInvalid} />
                  <Form.Control.Feedback type="invalid">Please enter your name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label className="FormLabel">Email</Form.Label>
                  <Form.Control className="FormInput" type="email" placeholder="Email" isInvalid={isEmailInvalid} />
                  <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridPhone">
                  <Form.Label className="FormLabel">Phone</Form.Label>
                  <Form.Control className="FormInput" type="phone" placeholder="Phone number" isInvalid={isPhoneInvalid} />
                  <Form.Control.Feedback type="invalid">Please enter a valid phone number.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridAddress">
                  <Form.Label className="FormLabel">Address</Form.Label>
                  <Form.Control className="FormInput" type="address" placeholder="Address" />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formGridSubject">
                <Form.Label className="FormLabel">Subject</Form.Label>
                <Form.Control className="FormInput" placeholder="Enter the subject" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGridMessage">
                <Form.Label className="FormLabel">Message</Form.Label>
                <Form.Control className="FormInput" as="textarea" rows={3} placeholder="Enter your message here" />
              </Form.Group>
              <Row>
              <Button id="SubmitButton" style={{
                textAlign: "center", 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                marginRight: "auto", 
                marginLeft: "auto",
                width: "30%"}} variant="outline-warning" onClick={handleSubmit}>
                  <Spinner style={{color: "rgb(188, 156, 88)", marginRight: ".5rem"}}           
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  hidden={spinnerHidden}/>
                  Submit
              </Button>
              </Row>
            </Form> 
          </Col>
        </Row>
      </Container>
    );
  }
  
  export default Contact;