import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row, Container, Image, Spinner} from 'react-bootstrap';
import { BiPhotoAlbum } from "react-icons/bi";

function OurWork(){
  const [content, setMyContent] = useState(null);

  const onMouseEnter = (e) => 
  {
    var overlayText = document.getElementById("HoverText" + e.target.id);
    overlayText.style.display = "block";
  }

  const onMouseLeave = (e) => 
  {
    var overlayText = document.getElementById("HoverText" + e.target.id);
    overlayText.style.display = "none";
  }

  useEffect(() => {
    fetch("/api/GoogleDriveData", {
      method: "GET",
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      }
    })
    .then(response => response.json())
    .then((responseData => 
    {
      const rows = [...Array(Math.ceil(responseData.Images.length / 3))];

      const productRows = rows.map((row, rowIdx) => responseData.Images.slice(rowIdx * 3, rowIdx * 3 + 3));

      const content = productRows.map((row, rowIdx) => (
        <Row key={rowIdx}>
          {row.map((item, columnIdx) =>
            (row.length < 3) ? 
            (<Col xs={12} sm={12} md={12} lg={6} style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginBottom: "2rem", marginTop: "1rem"}} key={item.AlbumHeader}>
                <div>
                  <h2 className="Our-Work-Heading">{item.AlbumHeader}</h2>
                  <div style={{position: "relative"}}>
                    <Image id={rowIdx.toString() + columnIdx.toString()} onClick={() => {window.open(item.GoogleAlbumLink, "_blank");}} className="OurWorkImage" src={item.LinkToAlbumCover} onMouseEnter={(onMouseEnter)} onMouseLeave={onMouseLeave} width={300} height={350} rounded/>
                    <BiPhotoAlbum id={"HoverText" + rowIdx + columnIdx} size={80} className="HoverImage"/>
                  </div>
                </div>
            </Col>): 
            (<Col xs={12} sm={12} md={12} lg={4} style={{display: "flex", justifyContent: "center", alignItems: "center", position: "relative", textAlign: "center", marginBottom: "2rem", marginTop: "1rem"}} key={item.AlbumHeader}>
              <div>
                <h2 className="Our-Work-Heading">{item.AlbumHeader}</h2>
                <div style={{position: "relative"}}>
                  <Image id={rowIdx.toString() + columnIdx.toString()} onClick={() => {window.open(item.GoogleAlbumLink, "_blank");}} className="OurWorkImage" src={item.LinkToAlbumCover} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} width={300} height={350} rounded/>
                  <BiPhotoAlbum id={"HoverText" + rowIdx + columnIdx} size={80} className="HoverImage"/>
                </div>
              </div>
            </Col>)
          )}
        </Row>
      ));

      setMyContent(content);

    }));
  }, []);

  if (!content) {
    return (
      <Container style={{marginTop: "auto"}}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{textAlign: "center"}}>
            <Spinner style={{color: "rgb(188, 156, 88)"}} animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }
  else{
    return (
      <Container style={{marginTop: "auto"}}>
        {content}
      </Container>
    );
  }
}
  
export default OurWork;