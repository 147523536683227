import React from 'react';
import { Col, Row, Container, Image } from 'react-bootstrap';
import HeadShot from '../Images/HeadShot.JPG'

function About() {
    return( 
    <Container fluid style={{marginTop: "auto"}}>
    <Row className="m-auto" style={{marginTop: "auto", width: "80%"}}>
      <Col xs={12} sm={12} md={6} style={{
        alignItems: "center", 
        justifyContent: "center",
        textAlign: "center"}}>
        <Image src={HeadShot} fluid rounded width="400"/>
      </Col>
      <Col xs={12} sm={12} md={6} style={{
        alignItems: "center", 
        justifyContent: "center",
        paddingTop: "1rem",
        marginTop: "auto",
        marginBottom: "auto"}}>
        <h2 className="About-Heading">About Us!</h2>
        <p className="About-Text">
          DB Outdoor Services is owned and operated by Dakota Biefeld.  Dakota grew up and still lives in Elkhorn.  
          He worked at a landscape supply company while in college and fell in love with the landscaping industry.  
          He now helps customers explore their outdoor dreams and transform their properties into beautiful entertainment spaces. 
          All projects are quoted, planned, and completed by Dakota, to ensure meticulous attention to detail and complete 
          satisfaction throughout the whole process. If you&apos;d like to explore your outdoor dreams contact us today!
        </p>
      </Col>
    </Row>
  </Container>
    )
  }
  
  export default About;