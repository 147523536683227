import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { BiSolidPhone } from "react-icons/bi";
import { BiSolidEnvelope } from "react-icons/bi";
import { FaMapMarkerAlt} from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";

function Footer(){
    return(
        <Container fluid style={{background: "#1E1C1B", marginTop: "auto", flex: "0 0 50px", paddingTop: "2rem"}}>
            <Col style={{
                display: "flex", 
                flexDirection: "column", 
                justifyContent: "center",
                margin: "auto",
                textAlign: "center",
                maxWidth: "50%",
             }}>
            <Row xs={6} sm={6} md={6}>
                <Col xs={12} sm={12} md={6} lg={3}>
                    <h5 className="Footer-Heading-h5">Address</h5>
                    <h6 className="Footer-Heading-h6">
                        <FaMapMarkerAlt /> <a className="Link-Override" href="https://www.google.com/maps/place/Elkhorn,+WI+53121/@42.6809827,-88.5735619,13z/data=!3m1!4b1!4m6!3m5!1s0x8805f215362772e3:0x2c2a8d409c42f77f!8m2!3d42.6727927!4d-88.5445447!16zL20vMDExY2Rs?entry=ttu">Elkhorn, WI</a>
                    </h6>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3}>
                    <h5 className="Footer-Heading-h5">Hours</h5>
                    <h6 className="Footer-Heading-h6"><FaClock /> Mon-Fri 7-5pm</h6>
                    <h6 className="Footer-Heading-h6"><FaClock /> Sat 7-12pm</h6>
                </Col>
                                <Col xs={12} sm={12} md={6} lg={3}>
                    <h5 className="Footer-Heading-h5">Contact Us</h5>
                    <h6 className="Footer-Heading-h6">
                        <a className="Link-Override" style={{paddingRight: "0.3rem"}} href="mailto:dakota@dboutdoorservicesllc.com">
                            <BiSolidEnvelope  size={30} />
                        </a>
                        <a className="Link-Override" href="tel:2623741339">
                            <BiSolidPhone size={30} />
                        </a>
                    </h6>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3}>
                    <h5 className="Footer-Heading-h5">Social Media</h5>
                    <h6 className="Footer-Heading-h6">
                        <a className="Link-Override" style={{paddingRight: "0.3rem"}} href="https://www.facebook.com/people/DB-Outdoor-Services-LLC/100089663005552/">
                            <FaFacebookSquare size={30} />
                        </a>
                        <a className="Link-Override" href="https://www.instagram.com/dboutdoorservices/">
                            <FaInstagramSquare size={30} />
                        </a>
                    </h6>
                </Col>
            </Row>
            <Row style={{paddingTop: "2rem"}}>
                <h6 className="Footer-Heading-h6">©2024 by DB Outdoor Services LLC.</h6>
            </Row>
        </Col>
        </Container>
    );
}

export default Footer;