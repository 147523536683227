import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import Softscape from '../Images/Softscape.jpg';
import Hardscape from '../Images/Hardscape.jpg';
import Grading from '../Images/Grading.jpg';
import Chainsaw from '../Images/Chainsaw.jpg';

function Services(){
  return (
    <Container style={{marginTop: "auto"}}>
    <Row className="my-auto" style={{paddingBottom: "1rem", paddingTop: "1rem"}}>
      <Col xs={12} sm={12} md={6} lg={6} style={{alignItems: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto"}}>
        <h2 className="Services-Heading">Softscapes</h2>
        <p className="Services-Text">Transform your flower beds with new mulch or stone and vibrant plants!</p>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} style={{textAlign: "center"}}>
        <Image src={Softscape} fluid rounded width="400"/>
      </Col>
    </Row>
    <Row className="my-auto" style={{paddingBottom: "1rem"}}>
      <Col xs={12} sm={12} md={6} lg={6} style={{alignItems: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto"}}>
        <h2 className="Services-Heading">Hardscapes</h2>
        <p className="Services-Text">Create a new entertainment space in your backyard with a brand new patio!</p>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} style={{textAlign: "center"}}>
        <Image src={Hardscape} fluid rounded width="400"/>
      </Col>
    </Row>
    <Row className="my-auto" style={{paddingBottom: "1rem"}}>
      <Col xs={12} sm={12} md={6} lg={6} style={{alignItems: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto"}}>
        <h2 className="Services-Heading">Grading/Drainage</h2>
        <p className="Services-Text">Ensure water adequately drains away from your house!</p>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} style={{textAlign: "center"}}>
        <Image src={Grading} fluid rounded width="400"/>
      </Col>
    </Row>
    <Row className="my-auto" style={{paddingBottom: "1rem"}}>
      <Col xs={12} sm={12} md={6} lg={6} style={{alignItems: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto"}}>
        <h2 className="Services-Heading">Tree Trimming</h2>
        <p className="Services-Text">Take care of your trees and clear up low hanging branches!</p>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} style={{textAlign: "center"}}>
        <Image src={Chainsaw} fluid rounded width="400"/>
      </Col>
    </Row>
  </Container>
  );
}
  
export default Services;