import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import MainDisplayPhoto from '../Images/CoverPhoto.png'
import Logo from '../Images/Color logo - no background.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Container } from 'react-bootstrap';
import ContactInfo from '../components/ContactInfo';

function Home() {
  return (
    <Container fluid>
      <Row className="my-auto">
        <Col xs={12} md={12} style={{position: "relative"}}>
          <Image src={MainDisplayPhoto} fluid/>
        </Col>
      </Row>
      <Row className="my-auto" style={{paddingBottom: "1rem"}}>
        <Col xs={7} sm={7} md={6} lg={4} style={{marginRight: "auto", marginLeft: "auto", marginTop: "1rem"}}>
          <Image src={Logo} fluid />
        </Col>
        <Col xs={12} sm={12} md={6} className="d-flex align-items-center" style={{marginTop: "1rem"}}>
          <ContactInfo message={"Contact Us!"}/>
        </Col>
      </Row>
    </Container>
    );
  }
  
  export default Home;