
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap'
import { BiSolidEnvelope } from "react-icons/bi";
import { BiSolidPhone } from "react-icons/bi";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";

ContactInfo.propTypes = 
{
  message: PropTypes.string
}

function ContactInfo({ message }) {

  const linesOfText = message.split("\n");

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h1 className="ContactUs">
            {linesOfText.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </h1>
          <Row className="my-auto">
            <hr
              style={{
              background: "rgb(188, 156, 88)",
              height: "2px",
              border: "none",
              width: "50%",
              textAlign: "center",
              justifyContent: "ceneter",
              marginLeft: "auto",
              marginRight: "auto",
             }}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h3 className="Email-Text" style={{textWrap: "wrap"}}><BiSolidEnvelope /> <a className="Link-Override" href="mailto:dakota@dboutdoorservicesllc.com">dakota@dboutdoorservicesllc.com</a></h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h5 className="PhoneNumber"><BiSolidPhone /> <a className="Link-Override" href="tel:2623741339">262-374-1339</a></h5>
        </Col>
      </Row>
      <Row className="my-auto">
        <Col xs={12} sm={12} md={12} lg={12} className="text-center">
          <a className="Link-Override" href="https://www.facebook.com/people/DB-Outdoor-Services-LLC/100089663005552/">
              <FaFacebookSquare size={"4rem"} />
          </a>
          <a className="Link-Override" href="https://www.instagram.com/dboutdoorservices/">
              <FaInstagramSquare size={"4rem"} />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactInfo;