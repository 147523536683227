import { React } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

ConfirmationModal.propTypes = 
{
  show: PropTypes.bool,
  toggleShow: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
}

function ConfirmationModal({show, toggleShow, title, text}) {
  return (
    <>
      <Modal show={show} onHide={toggleShow}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleShow}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;